import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import GravityForm from 'components/GravityForm'
import styled from "styled-components"

const Title = styled.h1`
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
`

const Section = styled.section`

button {
  max-width: 200px;
  margin-right: auto;
}

.form-footer {
  margin-top: 5px !important;
  margin-bottom: 35px !important;
}

form {
  max-width: 500px;
}

`


export default function Feedback() {
  const {
    feedback: {
      acf: {
        page_content: { title, description },
      },
      yoast_meta: yoast,
    },
  } = useStaticQuery(graphql`
    {
      feedback: wordpressPage(wordpress_id: { eq: 1164 }) {
        acf {
          page_content {
            title
            description
          }
        }
        yoast_meta {
          name
          content
          property
        }
      }
    }
  `)


  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />

      <Section className='container py-5'>
        <Title className='font-weight-xl color-text-main font-size-xxl'>{title}</Title>  

        <div
          className="d-flex flex-wrap pb-3"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <div className="form-wrapper">
          <GravityForm id={5} />
        </div>
      </Section>
      
    </Layout>
  )
}